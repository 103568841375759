<template>
    <div class="fileTYPEStxt">
        <div class="txt nl">
            <p class="title">FILE TYPES</p>

            <p class='p1' lang="nl"> Volgende bestandstypes kunnen we verwerken:
                <ul>
                    <li><div><p>Een PDF per kleur, duidelijk gelabeld [_CLR], in grijswaarden [grayscale]. Dit mag ook 1 PDF zijn met per kleur duidelijk gelabelde pagina's. Je kan hiervoor volgende <a href="RISOLVE-PDFforRISOOM.joboptions" target="_blank" rel="noopener noreferrer" download>preset</a> gebruiken.</p></div></li>
                    <li><div><p>Een PSD met per kleur duidelijk gelabelde lagen [CLR] in grijswaarden [grayscale] of een multichannel met duidelijk gelabelde kanalen [CLR].</p></div></li>
                    <li><div><p>Een EPS multichannel met per kleur duidelijk gelabelde kanalen [CLR].</p></div></li>
                    <li><div><p>Een InDesign package [folder met INDD] voor complexe projecten. Hierbij is het belangrijk dat er gewerkt wordt met spotkleuren genoemd volgens onderstaande kleurlabels.</p></div></li>
                    <li><div><p>Een Illustrator [AI] bestand is mogelijk maar enkel in de <i>print and prepress</i> of <i>print together</i> formules.</p></div></li>
                </ul>
            </p>
            <p class='p1' lang="nl">Kleurlabels [<a href="clrsRISOOMswatchV02.ase" target="_blank" rel="noopener noreferrer" download>adobe swatch</a>]:
                <ul>
                    <li><div>Black: BLCK</div></li>
                    <li><div>Fluorescent Orange: FLRNG</div></li>
                    <li><div>Fluorescent Pink: FLRPNK</div></li>
                    <li><div>Yellow: YLLW</div></li>
                    <li><div>Green: GRN</div></li>
                    <li><div>Teal: TL</div></li>
                    <li><div>Bright Red: BRGHTRD</div></li>
                    <li><div>Purple: PRPL</div></li>
                    <li><div>Blue: BL</div></li>
                </ul>
            </p>
            <p class='p1' lang="nl">Indien de keuze van screen al is gemaakt dan voeg je dit best toe in de naamgeving van het bestand:
                <ul>
                    <li><div>_GT voor <i>grain touch</i> of frequentie modulatie [FM]</div></li>
                    <li><div>_LPIYY voor <i>raster screen</i> of amplitude modulatie [AM]. De YY staat voor het aantal lijnen per inch [40 tot 105]. </div></li>
                </ul>
            </p>
        </div>

        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'fileTYPEStxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .fileTYPEStxt {
        background-color:#ff747765;
    }

    .fileTYPEStxt .txt {

    }    

    .fileTYPEStxt p.p1:first-of-type {
        margin-top: 50px;
    } 
    .fileTYPEStxt li div {

    }   
    .fileTYPEStxt ul {

    }   

    .fileTYPEStxt p.title{
        position: absolute;
        top: -50px;
        left: 20px;  
    }    



    @media only screen and (max-width: 1279px) {


    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>

