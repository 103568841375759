<template>
    <div class="risoomLogo">
        <img src="images/logoRISOOM.svg"/>
        <div class="p1">Een nieuwe plek in Mechelen voor RISO drukwerk >>>></div>
        

    </div>    
</template>

<script>
export default {
        name: 'risoomLogo',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .risoomLogo{
        margin-top: -125%;
        margin-left: -20vw;
        margin-right: -20vw;
        margin-bottom: 0px;
        }

    .p1{
        margin-bottom: 1vh;
        margin-left: 40vw;
        margin-right: 0px;
        margin-top: 2.5vh;
        color: #282522;
        text-align: right;      
        background-color: #fffdee;;
        padding: 1vh 1vh 1vh 1vh;
        line-height: 1.65;
        justify-content: right;
        max-width: 200px;

    }

    @media only screen and (min-width: 640px) {
        .p1{
            margin-bottom: 1vh;
            margin-left: 60vw;
            margin-right: 0px;
            margin-top: -10vh;
            max-width: 250px;
        }

        .risoomLogo{
            margin-top: -90%;
            }    
    }
      

    @media only screen and (min-width: 960px) {
        .p1{
            margin-bottom: 1vh;
            margin-left: 60vw;
            margin-right: 0px;
            margin-top: -15vh;
            max-width: 250px;
        }

        .risoomLogo{
            margin-top: -75%;
        margin-left: -10vw;
        margin-right: -10vw;
        margin-bottom: 0px;
            }  

    }  

    @media only screen and (min-width: 1280px) {
        .p1{
            margin-bottom: 1vh;
            margin-left: 20vw;
            margin-right: 0px;
            margin-top: -5vh;
            max-width: 250px;
        }

        .risoomLogo{
            margin-top: -250px;
        margin-left: -10vw;
        margin-right: -10vw;
        margin-bottom: 0px;
            }  

    }      

    @media only screen and (min-width: 1440px) {
        .p1{
            margin-bottom: 1vh;
            margin-left: 20vw;
            margin-right: 0px;
            margin-top: -10vh;
            max-width: 250px;
        }

        .risoomLogo{
            margin-top: -250px;
        margin-left: -7.5vw;
        margin-right: -7.5vw;
        margin-bottom: 0px;
            }  

    }  

    @media only screen and (min-width: 1760px) {
        .p1{
            margin-bottom: 1vh;
            margin-left: 15vw;
            margin-right: 0px;
            margin-top: -10vh;
            max-width: 250px;
        }

        .risoomLogo{
            margin-top: -250px;
            margin-left: -5vw;
            margin-right: -5vw;
            margin-bottom: 0px;
            }  

    }  

</style>

