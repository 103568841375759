<template>
  <div id="app" class="main">
			<div class="loader" id="loader">
				loading
				<div id="dots"></div>
			</div>   
            <div class="mainbuttons" >  
                <div class="button" :class="[filterM === 'contact' ? 'is-checked' : '']" v-scroll-to="{ 
                el: '#app', offset: -10 }"><span @click="mfilter(7)">CONTACT</span></div>  
                <div class="button"><span @click="lfilter()" v-html="filterL"></span></div>         
                <div class="button" id="middlebutton"><span></span></div>    
                <div class="button"><a href="https://shop.risoom.be">SHOP</a></div>                      
                <div class="button"  :class="[filterM === 'FILEPREP' ? 'is-checked' : '']" v-scroll-to="{ 
                el: '#app', offset: -10 }"><span @click="mfilter(6)">FILE PREP</span></div>                                                                 
                <div class="button"  :class="[filterM === 'LETSPRINT' ? 'is-checked' : '']" v-scroll-to="{ 
                el: '#app', offset: -10 }"><span @click="mfilter(458)">LET'S PRINT</span></div>                          
                <div class="button" :class="[filterM === 'RISO' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }"><span @click="mfilter(123)">RISO?</span></div>   
                <div id="XMPLS" class="button" :class="[filterM === 'EXAMPLES' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }"><span @click="mfilter(8)">EXAMPLES</span></div>                   
                            
            </div>     
            <div class="content-grid">                                 
                <isotope ref="cpt" id="isotope" :item-selector="'grid-item'" :list="list" :options='getOptions()' @filter="filterOption=arguments[0]" @sort="sortOption=arguments[0]" v-images-loaded.on.progress="layout">
                    <div v-for="item,index in list" :key="index" :class="item.colWdth">
                         <div class="grid-sizer"></div>
                         <p class="content"><v-runtime-template :template="item.content" :parent="parentComponent"></v-runtime-template></p> 
                    </div>                    
                </isotope>             
            </div>   			
		</div>
</template>

<script>
    import isotope from 'vueisotope';
    import imagesLoaded from 'vue-images-loaded';
    import VueScrollTo from 'vue-scrollto';
    import VRuntimeTemplate from "v-runtime-template";
    (function() {
        window.addEventListener("message",function(t){
            var e="https://www.paypalobjects.com/api/checkout.4.0.141.js";
            if(!document.querySelector('script[src="'+e+'"]')&&t.data&&"LOAD_PAYPAL"===t.data.action){
            var a=document.createElement("script");a.src=e,a.async=!0,a.dataset={paypalCheckout:!0,noBridge:!0,state:"ppxo_meta",env:"production"},document.body.append(a)
            }if(t.data&&"CALCONIC_UPDATE_HEIGHT"===t.data.action){
            var s=document.querySelector('iframe[src="https://app.calconic.com/api/embed/calculator/'+t.data.payload.id+'"]');
            if(s){s.height=t.data.payload.height}}
        });
    })();


    export default {
        name: 'App',
        components: {
            isotope,
            imagesLoaded, 
            VRuntimeTemplate,
        },    
        data: function () {
            "use strict";
            return {
                parentComponent: this,                
                list: [
                    {colWdth: "fivecol", order: 0, m: "-1", l:"0", content: "<p class='p1'></p>"},  
                    {colWdth: "twocol", order: 100, m: "2", l:"0", content: "<overKLEURENimg1/>"},  
                    {colWdth: "onecolSplit", order: 1, m: "0", l:"0", content: "<logoRISOOMimg/>"},    
                    {colWdth: "threecol", order: 2, m: "1", l:"1", content: "<overRISOtxt/>"},
                    {colWdth: "threecol", order: 2, m: "1", l:"2", content: "<ENoverRISOtxt/>"},
                    {colWdth: "twocol", order: 101, m: "2", l:"1", content: "<overKLEURENtxt/>"},
                    {colWdth: "twocol", order: 101, m: "2", l:"2", content: "<ENoverKLEURENtxt/>"},
                    {colWdth: "", order: 102, m: "2", l:"0", content: "<overKLEURENimg2/>"},
                    {colWdth: "twocol", order: 111, m: "3", l:"1", content: "<overIMPERFECTIEStxt/>"},
                    {colWdth: "twocol", order: 111, m: "3", l:"2", content: "<ENoverIMPERFECTIEStxt/>"},
                    {colWdth: "onecolSplit", order: 112, m: "3", l:"0", content: "<overIMPERFECTIESimg1/>"},     
                    {colWdth: "onecolSplit", order: 113, m: "3", l:"0", content: "<overIMPERFECTIESimg2/>"},   
                    {colWdth: "onecolSplit", order: 110, m: "3", l:"0", content: "<overIMPERFECTIESimg3/>"},                 
                    {colWdth: "onecolSplit", order: 114, m: "3", l:"0", content: "<overIMPERFECTIESimg4/>"}, 
                    {colWdth: "twocol", order: 115, m: "3", l:"1", content: "<overSCREENStxt/>"}, 
                    {colWdth: "twocol", order: 115, m: "3", l:"2", content: "<ENoverSCREENStxt/>"},                     
                    {colWdth: "twocol", order: 117, m: "3", l:"0", content: "<overSCREENSimg/>"}, 
                    {colWdth: "onecol", order: 118, m: "6", l:"0", content: "<extraIMG1/>"},                     
                    {colWdth: "threecol", order: 121, m: "4", l:"1", content: "<overPAPIERtxt/>"},    
                    {colWdth: "threecol", order: 121, m: "4", l:"2", content: "<ENoverPAPIERtxt/>"},                                       
                    {colWdth: "threecol", order: 120, m: "5", l:"1", content: "<letsPRINTtxt/>"},
                    {colWdth: "threecol", order: 120, m: "5", l:"2", content: "<ENletsPRINTtxt/>"},
                    {colWdth: "twocol", order: 122, m: "5", l:"0", content: "<priceCalculator/>"}, 
                    {colWdth: "twocol", order: 123, m: "5", l:"1", content: "<letsWRKSHPtxt/>"},
                    {colWdth: "twocol", order: 123, m: "5", l:"2", content: "<ENletsWRKSHPtxt/>"},
                    {colWdth: "twocol", order: 124, m: "7", l:"1", content: "<CNTCTtxt/>"},
                    {colWdth: "twocol", order: 124, m: "7", l:"2", content: "<enCNTCTtxt/>"},
                    {colWdth: "fivecol", order: 125, m: "2", l:"0", content: "<risoLayersIMG/>"},  
                    {colWdth: "fourcol", order: 128, m: "6", l:"1", content: "<filePREPtxt/>"},    
                    {colWdth: "fourcol", order: 128, m: "6", l:"2", content: "<ENfilePREPtxt/>"},                                       
                    {colWdth: "threecol", order: 129, m: "6", l:"1", content: "<fileTYPEStxt/>"},
                    {colWdth: "threecol", order: 129, m: "6", l:"2", content: "<ENfileTYPEStxt/>"},
                    {colWdth: "twocol", order: 130, m: "6", l:"0", content: "<extraIMG2/>"},                                 
                    {colWdth: "twocol", order: 1, m: "0", l:"1", content: "<logoRISOOM/>"},
                    {colWdth: "twocol", order: 1, m: "0", l:"2", content: "<ENlogoRISOOM/>"},
                    {colWdth: "twocol", order: 131, m: "8", l:"0", content: "<examplesTitle/>"},
                    {colWdth: "twocol", order: 132, m: "8", l:"0", content: "<examplesIMG1/>"},  
                    {colWdth: "twocol", order: 133, m: "8", l:"0", content: "<examplesIMG2/>"},      
                    {colWdth: "twocol", order: 135, m: "8", l:"0", content: "<examplesIMG3/>"},  
                    {colWdth: "twocol", order: 136, m: "8", l:"0", content: "<examplesIMG4/>"},   
                    {colWdth: "threecol", order: 134, m: "8", l:"0", content: "<examplesIMG5/>"},  
                    {colWdth: "threecol", order: 137, m: "8", l:"0", content: "<examplesIMG6/>"},  
                           
                    
                    // {colWdth: "fivecol", order: 999, m: "-1",  content: "<p class='p1' style='heigth:100px;'></p>"}, 
                    // {colWdth: "fivecol", order: 0, m: "-1",  content: "<p class='p1'></p>"},  
                    // {colWdth: "twocol", order: 100, m: "2",  content: "<overKLEURENimg1/>"},  
                    // {colWdth: "onecolSplit", order: 1, m: "0",  content: "<logoRISOOMimg/>"},    
                    // {colWdth: "threecol", order: 2, m: "1",  content: "<overRISOtxt/>"},
                    // {colWdth: "twocol", order: 101, m: "2",  content: "<overKLEURENtxt/>"},
                    // {colWdth: "", order: 102, m: "2",  content: "<overKLEURENimg2/>"},
                    // {colWdth: "twocol", order: 111, m: "3",  content: "<overIMPERFECTIEStxt/>"},
                    // {colWdth: "onecolSplit", order: 112, m: "3",  content: "<overIMPERFECTIESimg1/>"},     
                    // {colWdth: "onecolSplit", order: 113, m: "3",  content: "<overIMPERFECTIESimg2/>"},   
                    // {colWdth: "onecolSplit", order: 110, m: "3",  content: "<overIMPERFECTIESimg3/>"},                 
                    // {colWdth: "onecolSplit", order: 114, m: "3",  content: "<overIMPERFECTIESimg4/>"}, 
                    // {colWdth: "twocol", order: 115, m: "3",  content: "<overSCREENStxt/>"}, 
                    // {colWdth: "twocol", order: 117, m: "3",  content: "<overSCREENSimg/>"}, 
                    // {colWdth: "onecol", order: 118, m: "6",  content: "<extraIMG1/>"},                     
                    // {colWdth: "threecol", order: 121, m: "4",  content: "<overPAPIERtxt/>"},                     
                    // {colWdth: "threecol", order: 120, m: "5",  content: "<letsPRINTtxt/>"},
                    // {colWdth: "twocol", order: 122, m: "5",  content: "<priceCalculator/>"}, 
                    // {colWdth: "twocol", order: 123, m: "5",  content: "<letsWRKSHPtxt/>"},
                    // {colWdth: "twocol", order: 124, m: "7",  content: "<CNTCTtxt/>"},
                    // {colWdth: "fivecol", order: 125, m: "2",  content: "<risoLayersIMG/>"},  
                    // {colWdth: "fourcol", order: 128, m: "6",  content: "<filePREPtxt/>"},                      
                    // {colWdth: "threecol", order: 129, m: "6",  content: "<fileTYPEStxt/>"},
                    // {colWdth: "twocol", order: 130, m: "6",  content: "<extraIMG2/>"},                                 
                    // {colWdth: "twocol", order: 1, m: "0",  content: "<logoRISOOM/>"},
                    // {colWdth: "twocol", order: 1, m: "0", content: "<ENlogoRISOOM/>"},
                    // {colWdth: "twocol", order: 131, m: "8",  content: "<examplesTitle/>"},
                    // {colWdth: "twocol", order: 132, m: "8",  content: "<examplesIMG1/>"},  
                    // {colWdth: "twocol", order: 133, m: "8",  content: "<examplesIMG2/>"},      
                    // {colWdth: "twocol", order: 135, m: "8",  content: "<examplesIMG3/>"},  
                    // {colWdth: "twocol", order: 136, m: "8",  content: "<examplesIMG4/>"},   
                    // {colWdth: "threecol", order: 134, m: "8",  content: "<examplesIMG5/>"},  
                    // {colWdth: "threecol", order: 137, m: "8",  content: "<examplesIMG6/>"},  
                                   
                    {colWdth: "fivecol", order: 999, m: "-1",  content: "<p class='p1' style='heigth:100px;'></p>"},                     
                ],
                colWitdh: "",
                filterM: "all",
                filterL: "EN",
                optionsL: ["EN","NL"],
                optionsM: ["RISO", "KLEUREN", "IMPERFECTIES", "PAPIER", "LETSPRINT", "FILEPREP", "CONTACT", "EXAMPLES"],
                m: -1,
                l: 1,           
                filterSave: [-1.1],  
                windowWidth: window.outerWidth,
                windowHeight: window.outerHeight,
                xCoordsGRN: Array.from({length: 30000}, () => Math.random(0,1)),
                yCoordsGRN: Array.from({length: 30000}, () => Math.random(0,1)),
                xCoordsFLRNG: Array.from({length: 30000}, () => Math.random(0,1)),
                yCoordsFLRNG: Array.from({length: 30000}, () => Math.random(0,1)),
                xCoordsFLRPNK: Array.from({length: 30000}, () => Math.random(0,1)),
                yCoordsFLRPNK: Array.from({length: 30000}, () => Math.random(0,1)),

            };
        },
        methods: {
            getOptions: function() {
                "use strict";
                return {
                    masonry: {
                        columnWidth: '.grid-sizer'
                    },
                    itemSelector: ".grid-item",
                    transitionDuration: '1s',
                    getFilterData: {
                        "filter": (el) => {	
                                if (this.m == -1){
                                    var mBool = (el.m <= 1000)
                                } else if (this.m == 123){
                                    mBool = (el.m == 1 || el.m == 2 || el.m == 3 || el.m == -1);
                                } else if (this.m == 458 ){
                                    mBool = (el.m == 4 || el.m == 5 || el.m == 8 || el.m == -1);
                                } else if (this.m == 7 ){
                                    mBool = (el.m == 7 || el.m == 0 || el.m == -1);
                                } else if (this.m == 6 ){
                                    mBool = (el.m == 6 || el.m == -1);
                                } else if (this.m == 8 ){
                                    mBool = (el.m == 8 || el.m == -1);
                                } 
                                if (this.l == 1){
                                    var lBool = (el.l == 1 || el.l == 0)
                                }  else if (this.l== 2){
                                    var lBool = (el.l == 2|| el.l == 0 )
                                }       
                                var boolReturn = lBool & mBool         
                                return boolReturn;
                                // return mBool;
                        }
                        
                    },
                    getSortData: {
                        order: 'order'
                    },
                    sortBy: 'order'                
                }
            },	
            layout: function() {
                this.$refs.cpt.layout('masonry');
                this.$refs.cpt.filter("filter");
                document.getElementById("loader").style.visibility = "hidden";	
                document.body.style.overflow = "auto";	

                if (this.windowHeight > this.windowWidth){
                    var mltplyFactor = this.windowHeight
                    var timesFactor = 5
                } else {
                    var mltplyFactor = this.windowWidth
                    var timesFactor = 10
                };

                console.log(mltplyFactor*timesFactor)

                var bgData = document.getElementById('scttrGRN'); 
                for(var i = 1; i <= mltplyFactor*timesFactor; i++)
                {
                    bgData.insertAdjacentHTML( 'afterbegin', '<circle cx="' + this.xCoordsGRN[i] * mltplyFactor + '" cy="' + this.yCoordsGRN[i]*mltplyFactor + '" r="1"></circle>');
                };
                
                var bgData = document.getElementById('scttrFLRNG'); 
                for(var i = 1; i <= mltplyFactor*timesFactor-5000; i++)
                {
                    bgData.insertAdjacentHTML( 'afterbegin', '<circle cx="' + this.xCoordsFLRNG[i] * mltplyFactor + '" cy="' + this.yCoordsFLRNG[i]*mltplyFactor + '" r="1" ></circle>');
                }  ;

                var bgData = document.getElementById('scttrFLRPNK'); 
                for(var i = 1; i <= mltplyFactor*timesFactor-5000; i++)
                {
                    bgData.insertAdjacentHTML( 'afterbegin', '<circle cx="' + this.xCoordsFLRPNK[i] * mltplyFactor + '" cy="' + this.yCoordsFLRPNK[i]*mltplyFactor + '" r="1" ></circle>');
                }  ;               
            },
            mfilter: function(nm) {
                if (this.m != nm){
                        this.m = nm;
                        this.$refs.cpt.filter("filter");                    
                        if (nm != -1){
                            this.filterM = this.optionsM[nm-1];
                        } else if (nm == -1){
                            this.filterM = 'all';
                        }
                    }     
            }, 
            lfilter: function() {
                if (this.l == 1){
                    this.l = 2;
                    this.$refs.cpt.filter("filter");   
                    this.filterL = this.optionsL[1]                 
                } else if (this.l == 2) {
                    this.l = 1;
                    this.$refs.cpt.filter("filter");   
                    this.filterL = this.optionsL[0] 
                }
            },             
            reset: function() {	
                this.m = -1;
                this.filterM = 'all';
                this.$refs.cpt.filter("filter");
            },
            refresh: function(){
                if (this.windowWidth != window.outerWidth){
                    location.reload();
                }
                this.windowWidth = window.outerWidth
                this.windowHeight = window.outerHeight
            },
            en: function(){       
                for (let i=0; i < document.getElementsByClassName('en').length; i++) {
                    document.getElementsByClassName('en')[i].style.display = "inherit";	
                    document.getElementsByClassName('nl')[i].style.display = "none";   
                }      
            },
            nl: function() {
                    for (let i=0; i < document.getElementsByClassName('nl').length; i++) {
                        document.getElementsByClassName('nl')[i].style.display = "inherit";	
                        document.getElementsByClassName('en')[i].style.display = "none";  
                    }
            }
        },
        directives: {
            imagesLoaded,VueScrollTo
        }, 
        created() {
          window.addEventListener("resize", this.refresh);
        },
        destroyed() {
          window.removeEventListener("resize", this.refresh);
        },
    }
</script>

<style>
    * {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
    }

    html {
        height: 100%;
    }

    body {
        background-color: #fffdee;
        z-index: 0;
        color: #282522;
        max-width: 100vw;
        margin-left: 0vw;
        overflow: hidden;
        height: 100%;
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;  
        margin: 0 auto;
    }

    #loader{
        visibility: visible;
    }

    .loader{
        display: block;
        position: absolute;
        background-color: #fffdee;
        padding-top: 13.3333px;
        top: 0px; 
        bottom: -10px;
        width: 100%;
        opacity: 1;
        z-index: 999;
        margin: 0 auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: center;   
        font-size: calc(16px + 0.2vw);

    }

    .en{
        display:none;

    }


    #dots {
        /* the colors */
        --c1: #fff144;
        --c2: #ff9b8e;
        --c3: #00858f;
        --c4: #007cc7;
        --c5: #007cc7;
        /**/
        
        width: 65px; /* control the size */
        aspect-ratio: 8/5;
        --_g: no-repeat radial-gradient(#000 68%,#0000 71%);
        -webkit-mask: var(--_g),var(--_g),var(--_g);
        -webkit-mask-size: 25% 40%;
        background: 
            conic-gradient(var(--c1) 50%,var(--c4) 0) no-repeat,
            conic-gradient(var(--c2) 50%,var(--c5) 0) no-repeat;
        background-size: 200% 50%;
        animation: 
            back 4s infinite steps(1),
            load 2s infinite;
        margin:20px auto;

    }

    @keyframes load {
        0%    {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
        16.67%{-webkit-mask-position: 0% 100%,50% 0%  ,100% 0%  }
        33.33%{-webkit-mask-position: 0% 100%,50% 100%,100% 0%  }
        50%   {-webkit-mask-position: 0% 100%,50% 100%,100% 100%}
        66.67%{-webkit-mask-position: 0% 0%  ,50% 100%,100% 100%}
        83.33%{-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 100%}
        100%  {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
    }
    @keyframes back {
        0%,
        100%{background-position: 0%   0%,0%   100%}
        25% {background-position: 100% 0%,0%   100%}
        50% {background-position: 100% 0%,100% 100%}
        75% {background-position: 0%   0%,100% 100%}
    }

    .main{
        z-index: 0;
        margin: 0 auto;
    }

    #scttrGRN {
        fill: #00b36b85;
    }

    #scttrFLRNG {
        fill: #ff9b8e85;
    }  

    #scttrFLRPNK {
        fill: #ff8fdc85;
    } 

    .graph{
        position:fixed;
        height:100%;
        width:100%;  
        overflow: hidden;
    }
  

    /* prefix with transition name */
    .fade-enter-active {
      opacity: 1;
    }

    .fade-leave-active {
      opacity: 1;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .imgFade-enter-active {	
      z-index:120;
      opacity: 1;
    }

    .imgFade-leave-active {
      opacity: 1;
    }

    .imgFade-enter,
    .imgFade-leave-to {
        z-index: 110;
        opacity: 0;
    }

    /* ---- buttons ---- */


    .mainbuttons{
        position: fixed;   
        z-index: 4;
        display: -ms-flex;
        display: flex;
        width: 100%; 
        border: 2px solid #282522;

  
    }
     
    .button {
        color: #282522;
        font-size: calc(11px + 0.8vw);
        background-color: #fffdee;
        border-right: 2px solid #282522;
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;
        z-index:10;       
        padding: 5px 10px;      
        transition: all .5s ease;      
    }
  
    .button:last-of-type   {
        border-right-style: none;
    }

    #middlebutton{
        flex-grow: 1;
    }

    .button a{
        color: #282522;
        font-style: normal;
        text-decoration: none;
    }

    .button a:hover{
        font-style: italic;
        color: #00858f;
        cursor: pointer;
        background-color: #fffdee;
    }

    .button span:hover{
        font-style: italic;
        color: #00858f;
        cursor: pointer;
        background-color: #fffdee;
    }

    .button.is-checked,
    .button span.is-checked{
        color: #00858f;
        font-style: italic;
    }

    .custom-style{
        border-right: 2px solid #282522;
    }
        
    .v-dropdown-menu.custom-style .v-dropdown-menu__container {
        border-left: 2px solid #282522;
        border-bottom: 2px solid #282522;
        border-right: 2px solid #282522;
        background-color: #fffdee;
        min-width: 0px;
        width: inherit;
        margin-left: 0vw;
        padding-left: -5pxj
    }
    .v-dropdown-menu.custom-style .button{
        border: none;
    }   

    .v-dropdown-menu.custom-style .opacity-enter-active {
      transition: opacity 0.6s;
    }
    .v-dropdown-menu.custom-style .opacity-enter, .v-dropdown-menu.custom-style .opacity-leave-to {
      opacity: 0;
    }
    .v-dropdown-menu.custom-style.v-dropdown-menu--mode-hover .opacity-enter, .v-dropdown-menu.custom-style.v-dropdown-menu--mode-hover .opacity-leave-to {
      transition-delay: 0.3s;
    }



    /* ---- isotope ---- */

    .content-grid {
        margin: 0 auto;
        z-index: 3;    
        max-width: 75vw;     
    } 

    #isotope{ 
      margin: 0 auto;
      top: calc(10vh);
      background-color: transparent;
      z-index: 3;
    }
    /* clear fix */
    .grid:after {
      content: '';
      display: block;
      clear: both;
    }
    .isoDefault {
      min-height: 210px;
    }

    /* ---- .grid-item ---- */
    .grid-item {
        z-index: 0;
        height: auto;     
    }

    .grid-item img {
      display: block;
      max-width: 100%;
    }

    .grid-item .content {
        text-transform: none;
        padding: 40px;
        margin: 0px;
        color: #282522; 
        font-size: calc(16px + 0.4vw);
        overflow-wrap: break-word;
    }

    .grid-item .txt{
        padding: 1.5em 0em 1em 1em;
        position: relative;
        z-index: 3;        
    }
    
    .grid-item p.p1{

        margin: 1em 0.5em 0.5em 0.5em;  
        overflow-wrap: break-word;
        word-wrap: break-word;   
        word-break: break-word;
        line-height: 1.5;
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: manual;
        -moz-hyphens: manual;
        -webkit-hyphens: manual;
        hyphens: manual;   
        /*hyphenate-limit-chars: 6 3 3;
        hyphenate-limit-lines: 2;
        hyphenate-limit-last: always;
        hyphenate-limit-zone: 8%;*/
    }

    
    .grid-item a{
        color: #00868e;
        font-style: normal;
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
        -webkit-text-decoration-style: dotted;
                text-decoration-style: dotted;

    }  
    
    .grid-item a:hover{
        font-style: normal;
        color: #00858f;
        opacity: 1;
        text-decoration: none;
    }
    
    .grid-item p.title{
        font-size: calc(24px + 0.2vw);
        line-height: 1;
        padding: 20px;
        border: 2px solid #282522;
        margin-bottom: 25px;
        color: #282522;
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;
        width: fit-content;
    }

    .CLRbg {
        top: 45px;
        left: 50px;       
        mix-blend-mode: multiply;
        background-color: #ffffff;
        z-index: 1;
        position: absolute;
        content: '';   
        height: calc(100% - 80px);
        width: calc(100% - 80px); 
        padding: 40px;            

    }


    .CLRyllw {
        top: 50px;
        left: 50px;      
        mix-blend-mode: multiply;
        background-color: #fff14465;
        z-index: 2;
        position: absolute;
        content: '';
        height: calc(100% - 75px);
        width: calc(100% - 75px); 
    }     

    .CLRwht {
        top: 50px;
        left: 50px;      
        mix-blend-mode: multiply;
        background-color: #ffffff85;
        z-index: 2;
        position: absolute;
        content: '';
        height: calc(100% - 75px);
        width: calc(100% - 75px); 
    }      
    
    ul {
        list-style-type: ">";
        list-style-position: outside;
        font-size: calc(16px + 0.4vw);
        color: #282522;
        margin-right: 0.5vw;

    }

    li div {
        font-size: calc(16px + 0.4vw);  
        position: relative;
        top: 0vh;
        color: #282522;
        margin-bottom: 0px;
        text-indent: 1vw;       

    }

    li div p {
        text-indent: 0vw;
        margin-left: 1vw;
        margin-top:-0.5vw 

    }

    span.emph {
        font-weight: 500; 
        color: #282522;
    }  
    
    span.linkInText {
        color: #00858f;
        font-style: normal;
        cursor: pointer;
        display:inline-block;
        text-decoration: underline;
        text-underline-position: under;
        -webkit-text-decoration-style: dotted;
                text-decoration-style: dotted;
    }  
    
    span.linkInText:hover{
        font-style: normal;
        color: #00858f;
        opacity: 1;
        text-decoration: none;
    }
    
   
    em {
      font-style: italic;
    }    

    h2 { 
        color: #00858f;
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: calc(10px + 1vw); 
    }     
     
 
    .v-lazy-image {
        opacity: 0.5;
        transition: opacity 2s;
    }
    .v-lazy-image-loaded {
        opacity: 1
        
    }    
    
    /* ---- responsiveness ---- */
    

    @media only screen and (min-width: 0px) {
        .grid-sizer,
        .grid-item,
        .grid-item.twocol,
        .grid-item.threecol {
            width: 100%; 
        }
        #XMPLS {
            display: none;
        }
    }

   
    @media only screen and (max-width: 768px) {           

        .mainButton{
            height: 110px;
            width: 110px;
            background-size: 110px auto;               
        }
        .mainBar{
            display: block;
            grid-area: m;
            justify-self: center;    
            height: 110px;
        }
        #isotope{
            top:0px;
        }

        .grid-item{
            min-width: 210px;
        } 
                 
    }
    
    @media only screen and (max-width: 1279px) {
        .agile {
            display: none;
        }
        .dropdownlistO {
            display: none;
            
        }
        .grid-item .content {
            padding-left: 10px;
            padding-right: 10px;
        }    
        
        .CLRbg {
            top: 50px;
            left: 20px;              
            padding-left: 0px;            
            padding-right: 0px;
            width: calc(100% - 25px); 
            height: calc(100% - 83px); 
        }


        .CLRyllw {
            top: 48px;
            left: 20px;      
            width: calc(100% - 20px); 
        }     

        .CLRwht {
            top: 50px;
            left: 10px;      
            width: calc(100% - 0px); 
        }          
    } 
    

    @media only screen and (min-width: 800px) {
        .grid-sizer,
        .grid-item.onecolSplit{
            width: 50%; 
        }        
        .grid-item{
            width: 100%; 
        }
        .grid-item.twocol {
            width: 100%;
        }
        .grid-item.threecol {
            width: 100%;
        }
        .grid-item.fourcol {
            width: 100%;
        } 
        .grid-item.fivecol {
            width: 100%;
        }  
        #XMPLS {
            display: inherit;
        }        
    }


    @media only screen and (min-width: 1280px) {
        .grid-sizer,
        .grid-item{
            width: 20%; 
        }
        .grid-item.onecolSplit{
            width: 30%; 
        }          
        .grid-item.twocol {
            width: 40%;
        }
        .grid-item.threecol {
            width: 60%;
        }
        .grid-item.fourcol {
            width: 80%;
        } 
        .grid-item.fivecol {
            width: 100%;
        }         
    }


    @media only screen and (min-width: 1760px) {
        .grid-sizer,
        .grid-item{
            width: 20%; 
        }
        .grid-item.onecolSplit{
            width: 20%; 
        }        
        .grid-item.twocol {
            width: 40%;
        }
        .grid-item.threecol {
            width: 60%;
        }
        .grid-item.fourcol {
            width: 80%;
        }   
        .grid-item.fivecol {
            width: 100%;
        }                   
    }

    @supports not ((display: -ms-grid) or (display: grid)) {
      .info > * {
        width: 620px;
        margin: 0 auto;
      }
    }	

    :root {
        color-scheme: only light;
    }    
    
    /*-- agile --*/
    
    .wCaption .agile__dots {
        bottom: 0.2vh;
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
    .wCaption .agile__dot {
      margin: 0 1vw;
    }
    .wCaption .agile__dot button {
        background-color: #f1f2eb;
        border: 1px solid #f1f2eb;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
        width: 10px;
    }
    .wCaption .agile__dot--current button, .agile__dot:hover button {
        background-color: #00858f;
        border: 1px solid #00858f;
    }
    
    .wCaption .agile__nav-button {
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;    
        background: transparent;
        border: none;
        color: #f1f2eb;
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        bottom: 0vh;
        -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
        width: 5vw;
    }
    .wCaption .agile__nav-button:hover {
        color: #00868e;
        font-size: 30px;
    }
    .wCaption .agile__nav-button--prev {
        left: 0.4vw;
        text-align: left;
    }
    .wCaption .agile__nav-button--next {
        right: 1vw;
        text-align: right;
    }
    
    .wCaption .agile__caption{
        font-size: calc(10px + 0.2vw);
        padding-top: 0.25vh;
        text-align: right;
        height: 0;
    }
    
    figcaption{
        font-size: calc(10px + 0.2vw);
        padding-top: 0.25vh;
        text-align: right;
    }

    .agile__dots {
        bottom: 0.2vh;
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
    .agile__dot {
      margin: 0 1vw;
    }
    .agile__dot button {
        background-color: #f1f2eb;
        border: 1px solid #f1f2eb;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
        width: 10px;
    }
    .agile__dot--current button, .agile__dot:hover button {
        background-color: #00858f;
        border: 1px solid #00858f;
    }
    
    .agile__nav-button {
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;       
        background: transparent;
        border: none;
        color: #f1f2eb;
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        bottom: 0.0vh;
        -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
        width: 5vw;
    }
    .agile__nav-button:hover {
        color: #00868e;
        font-size: 30px;
    }
    .agile__nav-button--prev {
        left: 0.4vw;
        text-align: left;
    }
    .agile__nav-button--next {
        right: 1vw;
        text-align: right;
    }



    
</style>



