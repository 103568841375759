<template>
    <div class="overSCREENStxt">
        <div class="txt">
            <p class="title">SCREENS</p>
            <p class='p1' lang="nl">Een RISO machine is een drukmachine waarbij het drukwerk op een zeef van washi papier of zogenaamde <i>master</i> wordt geprikt. Daarna wordt de inkt via een cilindervormige drum door de zeef op het papier geperst. Die gaatjes prikken gaat op 3 verschillende manieren:
                <ul>
                    <li><div><p>Ofwel met frequentie modulatie, ook wel <i>grain touch</i> genoemd. De gaatjes blijven even groot en worden in een willekeurig patroon geplaatst dat de inhoud van het te printen object volgt. Hoe donkerder, hoe meer gaatjes er worden gemaakt. Dit werkt heel goed om detail te maximaliseren maar lichte tinten [&lt; 10%] worden vreemd weergegeven.</p></div></li>
                    <li><div><p>Ofwel met amplitude modulatie, ook wel <i>raster screen</i> genoemd. De gaatjes worden in een vast patroon geplaatst maar verschillen in grootte. Hoe donkerder, hoe groter het gaatje. Dat vast patroon kan zelf worden bepaald aan de hand van de LPI of <i>lines per inch</i>, die kan variëren tussen 38 en 200. Hoe hoger de LPI, hoe meer detail, maar, dit leidt ook tot minder schakeringen. Het beeld wordt daardoor algemeen donkerder, want lichte tinten kunnen niet meer getoond worden.</p></div></li>
                    <li><div><p>Als laatste kan er ook op een software matige manier een beeld omgezet worden naar een bitmap met zwart of witte pixels. Dat kan gewoon met Photoshop, waar je met <i>halftone screen</i> hetzelfde kan bereiken wat de machine intern doet. <a href="https://stochaster.org/stochaster.html" target="_blank" rel="noopener noreferrer">Stochaster</a> gaat nog een stap je verder door frequentie en amplitude modulatie te combineren: beschikbaar in <i>print and prepress</i> of <i>print together</i> formule.</p></div></li>
                </ul>
             </p>
             <p class='p1' lang="nl">Bijhorende figuur geeft een close-up vlak van 35% grijswaarde geprint in verschillende <i>screens</i>, vlnr. 75LPI, 38LPI, 200LPI, <i>grain touch</i>, 105LPI en bovenaan het <i>stochaster screen</i>.</p>             
        </div>
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'overSCREENStxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overSCREENStxt {
        background-color:#ff747765;
    }

    .overSCREENStxt .txt {

    }    

    .overSCREENStxt p.title{
        position: absolute;
        top: -55px;
            left: 50px;     
    }    

    td, th {
        border: 1px solid #282522;
        text-align: left;
        padding-right: 10px;
        padding-left: 5px;
        font-weight: inherit;
    }    

    table {
        margin-left: 20px;
        margin-right: 40px;
        margin-bottom:20px;
        font-size: calc(15px + 0.2vw);
        border: 1px solid #282522;
        border-collapse: collapse; 
    }


    @media only screen and (max-width: 1279px) {
        .overSCREENStxt p.title{
            position: absolute;
            top: -55px;
            left: 50px; 
        }   

    } 
    

    @media only screen and (max-width: 680px) {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #282522; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #282522; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 2px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}

    td:nth-of-type(1):before { content: "PAPIER"; }
	td:nth-of-type(2):before { content: "GSM"; }
	td:nth-of-type(3):before { content: "RECYCLED?"; }
	td:nth-of-type(4):before { content: "KLEUR"; }
	td:nth-of-type(5):before { content: "PRIJS"; }
	
}
      

    @media only screen and (min-width: 1850px) {


    }  
</style>

