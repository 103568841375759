<template>
    <div class="fileTYPEStxt">
        <div class="txt">
            <p class="title">FILE TYPES</p>

            <p class='p1' lang="nl"> We can process following file types:
                <ul>
                    <li><div><p>One pdf per colour, clearly labeled [_CLR], in grayscale. Or, a single pdf with one clearly labeled page per colour. You can use the following <a href="RISOLVE-PDFforRISOOM.joboptions" target="_blank" rel="noopener noreferrer" download>preset</a>.</p></div></li>
                    <li><div><p>A psd, with one layer per colour, clearly labeled [CLR], in grayscale. Or, a multichannel with clearly labeled channels [CLR].</p></div></li>
                    <li><div><p>An EPS multichannel one channel per colour, clearly labeled [CLR].</p></div></li>
                    <li><div><p>An InDesign package [folder labeled INDD] for more complex work. In this case, it is important to use spot colours according to the below mentioned colour labels.</p></div></li>
                    <li><div><p>An illustrator [AI] file is possible but only in the <i>print and prepress</i> or <i>print together</i> formulas.</p></div></li>
                </ul>
            </p>
            <p class='p1' lang="nl">Colour labels [<a href="clrsRISOOMswatchV02.ase" target="_blank" rel="noopener noreferrer" download>adobe swatch</a>]:
                <ul>
                    <li><div>Black: BLCK</div></li>
                    <li><div>Fluorescent Orange: FLRNG</div></li>
                    <li><div>Fluorescent Pink: FLRPNK</div></li>
                    <li><div>Yellow: YLLW</div></li>
                    <li><div>Green: GRN</div></li>
                    <li><div>Teal: TL</div></li>
                    <li><div>Bright Red: BRGHTRD</div></li>
                    <li><div>Purple: PRPL</div></li>
                    <li><div>Blue: BL</div></li>                    
                </ul>
            </p>
            <p class='p1' lang="nl">If you already chose a screen method , it is best to indicate this in the filename as well:
                <ul>
                    <li><div>_GT for <i>grain touch</i> or frequentie modulation [FM]</div></li>
                    <li><div>_LPIYY for <i>raster screen</i> or amplitude modulatie [AM]. The YY is for the required lines per inch [40 tot 105]. </div></li>
                </ul>
            </p>
        </div>        
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'fileTYPEStxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .fileTYPEStxt {
        background-color:#ff747765;
    }

    .fileTYPEStxt .txt {

    }    

    .fileTYPEStxt p.p1:first-of-type {
        margin-top: 50px;
    } 
    .fileTYPEStxt li div {

    }   
    .fileTYPEStxt ul {

    }   

    .fileTYPEStxt p.title{
        position: absolute;
        top: -50px;
        left: 20px;  
    }    



    @media only screen and (max-width: 1279px) {


    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>

