<template>
    <div class="risoLayersIMG">

            <div class="LYRS">
               
                
                <div class="LYRS-item" style="color: #282522">
                    <div class=LYRimg><img src="images/risoLayers01alt.png"/></div>
                    <div class="LYRname"><span>YELLOW</span> <br><p></p></div>	
                </div>
                <div class="plus">+</div>
                <div class="LYRS-item" style="color: #282522">
                    <div class=LYRimg><img src="images/risoLayers02alt.png"/></div>
                    <div class="LYRname"><span>FLUO ORANGE</span><br><p></p></div>	
                </div>
                <div class="plus">+</div>
                <div class="LYRS-item" style="color: #282522">
                    <div class=LYRimg><img src="images/risoLayers03alt.png"/></div>
                    <div class="LYRname"><span>TEAL</span><br><p></p></div>	
                </div>
                <div class="plus">=</div>
                <div class="LYRS-item" style="color: #282522">
                    <div class=LYRimg ><img src="images/risoLayers04alt.png"/></div>
                    <div class="LYRname"><span>RISO MAGIC!</span><br><p></p></div>	
                </div>                


            </div>


    </div>    
</template>

<script>
    export default {
        name: 'risoLayersIMG',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .risoLayersIMG {


    }

    .risoLayersIMG .txt {

    }    
    
    .LYRS {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;        
        margin-bottom: 0vh;
        margin-top: -10vh;


    }

    .LYRS-item{
        max-width: 50%;
        margin: 0px;

    }

    .plus{
        font-size: calc(20px + 1vw);
        padding:10px;
    }

    .LYRname{
        font-size: calc(20px + 0.2vw);
        margin-top: -25px;
        text-align: center;


    }

    .LYRname span{
        background-color: #fffdee;
    }
    .LYRimg img{

        padding: 10px;
    }   
    

   

    @media only screen and (min-width: 1280px) {
        .LYRS-item{
            max-width: 35%;
        } 

    } 
      

    @media only screen and (min-width: 1824px) {
        .LYRS-item{
            max-width: 22.5%;
        }

    }  
</style>

