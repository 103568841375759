<template>
    <div class="examplesIMG5">

            <div class="XMPLs">

                <img src="images/examples/carinaNebula01.png"/>
                <div class="caption">
                    <span> <i> CARINAL NEBULA</i> by <a href="https://www.jwst.nasa.gov/" target="_blank" rel="noopener noreferrer">JWST</a> in <font color="#282522">&#x25B2;</font><font color="#007679">&#x25B2;</font><font color="#ff7477">&#x25B2;</font><font color="#ffe800">&#x25B2;</font> <br> on Metapaper Extra Rough Recycled 120 gsm</span>
                </div>
            

            </div>



    </div>    
</template>

<script>
    export default {
        name: 'examplesIMG5',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .examplesIMG5 {


    }

    .examplesIMG5 .txt {

    }    
    
    .XMPLs {
        align-items: flex-end;
        display: flex;
      
    }

    .XMPLs img{


    }
    
    .XMPLs .caption {
        bottom:10px;
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: right;   
        font-size: calc(14px + 0.4vw);
        writing-mode: vertical-rl;

    }
    span{
        background-color: #fffdee;
    }  
   

    @media only screen and (max-width: 1650px) {
        .XMPLs {


}  

} 
      

</style>

